import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import InfiniteScroll from "./pages/InfiniteScroll/InfiniteScroll";

import "./app.scss";




function App(props) {
  return (
    <div className="app">
      <div className="container">
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/info"  element={<InfiniteScroll />}></Route>
          
        </Routes>
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default App;
