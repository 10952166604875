import React, { useEffect, useState } from "react";
import { base_url } from "../../baseUrl";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../components/Loader/Loader";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// mui
import Dialog from "@mui/material/Dialog";

// scss
import "./main.scss";
import "swiper/css";
import "swiper/css/effect-cards";

// images
import book2 from "../../assets/image/book2.png";
import close from "../../assets/svg/close.svg";
import add from "../../assets/svg/plus.svg";
import minus from "../../assets/svg/minus.svg";
import { ReactComponent as Baskett } from "../../assets/svg/Shopping Card.svg";
import burgerClose from "../../assets/svg/burgerClose.svg";

const Main = () => {
  const [cartItems, setCartItems] = useState([]);
  const [categories, setCategories] = useState(null);
  const [books, setBooks] = useState(null);
  const [bookView, setBookView] = useState(null);
  const [categoryId, setCategoryId] = useState(1);
  const [openBasket, setOpenBasket] = React.useState(false);
  const [input, setInput] = React.useState(null);
  const [loader2, setLoader2] = useState(false);
  const [burgerOpen, setBurgerOpen] = React.useState(false);
  const [shadow, setShadow] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      scrollFunction();
    });

    return () => {
      window.removeEventListener("scroll", () => {
        scrollFunction();
      });
    };
  }, []);

  function scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      setShadow("active");
    } else {
      setShadow("inActive");
    }
  }

  useEffect(() => {
    fetch(`${base_url}/category/index`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategories(result.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  useEffect(() => {
    fetch(
      `${base_url}/category/${categoryId}/show${input ? "?name=" + input : ""}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setBooks(result.data);
        setLoader2(true);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [categoryId, input]);

  function increaseCartQuantity() {
    if (bookView) {
      setCartItems((currItems) => {
        if (currItems.find((item) => item.id === bookView.id) == null) {
          return [
            ...currItems,
            {
              id: bookView.id,
              quantity: 1,
              name: bookView.name,
              price: bookView.price,
            },
          ];
        } else {
          return currItems.map((item) => {
            if (item.id === bookView.id) {
              return {
                ...item,
                quantity: item.quantity + 1,
              };
            } else {
              return item;
            }
          });
        }
      });
    }
  }

  function decreaseCartQuantity() {
    if (bookView) {
      setCartItems((currItems) => {
        if (currItems.find((item) => item.id === bookView.id)?.quantity === 1) {
          return currItems.filter((item) => item.id !== bookView.id);
        } else {
          return currItems.map((item) => {
            if (item.id === bookView.id) {
              return { ...item, quantity: item.quantity - 1 };
            } else {
              return item;
            }
          });
        }
      });
    }
  }

  function removeFromCart() {
    if (bookView) {
      setCartItems((currItems) => {
        return currItems.filter((item) => item.id !== bookView.id);
      });
    }
  }

  function removeFromBasket(id) {
    if (id) {
      setCartItems((currItems) => {
        return currItems.filter((item) => item.id !== id);
      });
    }
  }

  const handleClickOpenBasket = () => {
    setOpenBasket(true);
    setBookView(null);
  };

  const handleCloseBasket = () => {
    setOpenBasket(false);
    setBookView(null);
  };

  const handleClickOpenAdd = (id) => {
    if (id && books) {
      setBookView(books.find((el) => el.id === id));
      toggleDrawer(true);
      // setOpenAdd(true);
    }
  };

  const handleCloseAdd = () => {
    setBookView(null);
    removeFromCart();
    toggleDrawer(false);
    // setOpenAdd(false);
  };

  const handleOrder = () => {
    if (
      cartItems &&
      cartItems.length &&
      window.Telegram.WebApp.initDataUnsafe.user.id &&
      window.Telegram.WebApp.initDataUnsafe.user.username
    ) {
      let temp = [];
      cartItems.map((el) => {
        temp.push({
          item_id: el.id,
          quantity: el.quantity,
        });
      });

      fetch(`${base_url}/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: temp,
          telegram_user_id: window.Telegram.WebApp.initDataUnsafe.user.id,
          telegram_user_name:
            window.Telegram.WebApp.initDataUnsafe.user.username,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            handleCloseBasket();
            setCartItems([]);
            toast.success("Buyurtmangiz qabul qilindi");
            window.Telegram.WebApp.close();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.info("Mahsulotlarni tanlang!");
    }
  };
  const toggleDrawer = (open) => {
    setBurgerOpen(open);
  };

  const summ =
    cartItems.length &&
    cartItems.reduce((acc, { quantity, price }) => {
      return (acc += Number(quantity) * Number(price));
    }, 0);

  const handleNumberFormatter = (number) => {
    return number.toLocaleString("en-US");
  };

  return (
    <div className="main-container">
      <ToastContainer />
      <div className={`main-tt ${shadow}`}>
        {/* Shop cart */}
        <Dialog
          open={openBasket}
          onClose={handleCloseBasket}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {cartItems.length ? (
            <div className="basket-popup">
              <img
                src={close}
                alt=""
                className="basket-popup-close"
                onClick={handleCloseBasket}
              />
              <div>
                {" "}
                <h2 className="basket-popup-h2">Buyurtmalar</h2>
                <div className="basket-popup-items">
                  {cartItems.length
                    ? cartItems.map((el, index) => {
                        return (
                          <div className="basket-popup-item" key={index}>
                            <span className="span-1">{el.name}</span>
                            <span className="span-2">
                              {el.quantity} ta ={" "}
                              {handleNumberFormatter(el.price * el.quantity)}{" "}
                              so'm
                              <span>
                                {" "}
                                <img
                                  src={close}
                                  alt=""
                                  onClick={() => removeFromBasket(el.id)}
                                />
                              </span>
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
              <button className="basket-popup-btn" onClick={handleOrder}>
                Buyurtma berish
              </button>
            </div>
          ) : (
            <div className="basket-popup-empty">Mahsulotlarni tanlang!</div>
          )}
        </Dialog>

        <div className="category-title">
          <input
            value={input}
            placeholder="Qidirish..."
            className="category-title-input"
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />

          <div
            variant="outlined"
            onClick={() => {
              handleClickOpenBasket();
            }}
            className="basket active"
          >
            <Baskett className="basket-img" />

            {cartItems && cartItems.length ? <div className="div"></div> : ""}
          </div>
        </div>
      </div>

      <div className="bottom-btns">
        <div className="bottom-btn-1">{handleNumberFormatter(summ)} so'm</div>
        <button className="bottom-btn-2" onClick={handleOrder}>
          Buyurtma berish
        </button>
      </div>

      {/* Categories */}
      <div className="category-cards">
        <Swiper
          watchOverflow
          slidesPerView={1.5}
          pagination={{
            clickable: true,
          }}
          className="mySwiper"
        >
          {categories
            ? categories.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={
                        el.id === categoryId
                          ? "category-card active"
                          : "category-card"
                      }
                      onClick={() => {
                        setCategoryId(el.id);
                        setLoader2(false);
                      }}
                    >
                      <p>{el.name}</p>
                    </div>
                  </SwiperSlide>
                );
              })
            : ""}
        </Swiper>
      </div>

      {loader2 ? (
        <div className="main-boby">
          <div className="category-items">
            {books
              ? books.map(({ id, image, name, price }, index) => {
                  return (
                    <div className="category-item" key={id}>
                      <div>
                        <div className="category-item-img">
                          <img src={image ? image : book2} alt="book" />
                        </div>
                        <div className="category-item-info">
                          <h2>{name}</h2>
                          <h3>{handleNumberFormatter(price)} so’m</h3>
                        </div>
                      </div>

                      <div
                        className="category-item-add"
                        onClick={() => {
                          handleClickOpenAdd(id);
                        }}
                      >
                        <img src={add} alt="" /> QO'SHISH
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <SwipeableDrawer
        anchor="bottom"
        open={burgerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {bookView ? (
          <div className="add-basket-popup">
            {/* <img
              src={close}
              alt="close"
              className="add-close"
              onClick={() => {
                toggleDrawer(false);
              }}
            /> */}
            <div className="add-close">
              <img src={burgerClose} alt="" />
            </div>
            <div className="add-basket-popup-container">
              <div className="item-image">
                <img src={bookView.image ? bookView.image : book2} alt="book" />
              </div>
              <div className="add-basket-popup-info">
                <div className="item-info">
                  <div className="item-info-div">
                    <b>Name</b>
                    <span>{bookView.name}</span>
                  </div>

                  <div className="item-info-div">
                    <b>Price</b>
                    <span>
                      {handleNumberFormatter(bookView.price)} so'm{" "}
                      <b>
                        x{" "}
                        {cartItems.find((el) => el.id === bookView.id)?.quantity
                          ? cartItems.find((el) => el.id === bookView.id)
                              ?.quantity
                          : 0}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="popup-add-bottom">
                  <div className="count">
                    <div className="count-2">
                      {handleNumberFormatter(
                        bookView.price *
                          (cartItems.find((el) => el.id === bookView.id)
                            ?.quantity
                            ? cartItems.find((el) => el.id === bookView.id)
                                ?.quantity
                            : 0)
                      )}{" "}
                      so'm
                    </div>
                  </div>
                  <div className="item-btns">
                    <div
                      className="close"
                      onClick={() => decreaseCartQuantity()}
                    >
                      <img src={minus} alt="" />
                    </div>
                    <div className="add" onClick={() => increaseCartQuantity()}>
                      <img src={add} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-btn-add-to-bsk-btns">
              <div
                className="item-btn-add-to-bsk"
                onClick={() => {
                  handleCloseAdd();
                }}
              >
                O'chirish
              </div>
              <div
                className="item-btn-add-to-bsk-2"
                onClick={() => {
                  toggleDrawer(false);
                  setBookView(null);
                }}
              >
                Qo'shish
              </div>
            </div>
          </div>
        ) : null}
      </SwipeableDrawer>
      {/* Book View */}
      {/* <Dialog
        open={openAdd}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {bookView ? (
          <div className="add-basket-popup">
            <img
              src={close}
              alt="close"
              className="add-close"
              onClick={() => {
                handleCloseAdd();
              }}
            />
            <div className="add-basket-popup-container">
              <div className="item-image">
                <img src={bookView.image ? bookView.image : book2} alt="book" />
              </div>
              <div className="add-basket-popup-info">
                <div className="item-info">
                  <div className="item-info-div">
                    <b>Name</b>
                    <span>{bookView.name}</span>
                  </div>

                  <div className="item-info-div">
                    <b>Price</b>
                    <span>
                      {bookView.price} so'm{" "}
                      <b>
                        x{" "}
                        {cartItems.find((el) => el.id === bookView.id)?.quantity
                          ? cartItems.find((el) => el.id === bookView.id)
                              ?.quantity
                          : 0}
                      </b>
                    </span>
                  </div>
                </div>
                <div className="popup-add-bottom">
                  <div className="count">
                    <div className="count-2">
                      {bookView.price *
                        (cartItems.find((el) => el.id === bookView.id)?.quantity
                          ? cartItems.find((el) => el.id === bookView.id)
                              ?.quantity
                          : 0)}{" "}
                      so'm
                    </div>
                  </div>
                  <div className="item-btns">
                    <div
                      className="close"
                      onClick={() => decreaseCartQuantity()}
                    >
                      <img src={minus} alt="" />
                    </div>
                    <div className="add" onClick={() => increaseCartQuantity()}>
                      <img src={add} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item-btn-add-to-bsk-btns">
              <div
                className="item-btn-add-to-bsk"
                onClick={() => {
                  handleCloseAdd();
                }}
              >
                O'chirish
              </div>
              <div
                className="item-btn-add-to-bsk-2"
                onClick={() => {
                  setOpenAdd(false);
                  setBookView(null);
                }}
              >
                Qo'shish
              </div>
            </div>
          </div>
        ) : null}
      </Dialog> */}
    </div>
  );
};

export default Main;
