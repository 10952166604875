import React from "react";
import "./loader.scss";

export default function Loader() {
  return (
    <div className="preloader-dots" style={{ width: "100%", height: "75vh" }}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
}
